import React from 'react'
import Layout from '../components/Layout'
import Header from '../components/Header'
import Hero from '../components/Hero'

const AboutPageContent = () => {
  return (
    <div class="main">
      <section class="section">
        <div class="section__inner section__inner--small">
          <div class="section-content">
            <ul class="section-content__list">
              <li class="section-content__list-item">
                <div class="section-content__title">
                  <p class="section-content__title-txt">基本情報</p>
                </div>
                <p class="section-content__body">- 株式会社もばらぶ</p>
                <p class="section-content__body">- 設立: 2013年6月</p>
                <p class="section-content__body">- 本店所在地: 千葉県茂原市</p>
                <p class="section-content__body">- 資本金: 100万円</p>
              </li>
              <li class="section-content__list-item">
                <div class="section-content__title">
                  <p class="section-content__title-txt">事業内容</p>
                </div>
                <h3 class="section-content__body">ソフトウェア受託開発</h3>
                <h3 class="section-content__body">リモートワーク導入・海外進出等支援、協業</h3>
                <h3 class="section-content__body">IT・ソフトウェア領域に特化したオンライン英会話レッスン「モバプロ」</h3>
                <h3 class="section-content__body">初心者PMサポートサービス「Toro」</h3>
              </li>
              <li class="section-content__list-item">
                <div class="section-content__title">
                  <p class="section-content__title-txt">働く人</p>
                </div>
                <p class="section-content__body">- メンバー数 29名</p>
                <p class="section-content__body">- 雇用契約 16名、業務委託 13名</p>
                <p class="section-content__body">- フルリモートワーク率100%</p>
                <p class="section-content__body">- フルタイム 10名、時短勤務 19名</p>
                <p class="section-content__body">- 男性 21名、女性 8名</p>
                <p class="section-content__body">- 海外居住 4名、国内居住 25名</p>
              </li>
              <li class="section-content__list-item">
                <div class="section-content__title">
                  <p class="section-content__title-txt">関連会社</p>
                </div>
                <p class="section-content__body">- マレーシアオフィス</p>
                <p class="section-content__body">- フィリピンオフィス</p>
              </li>
              <li class="section-content__list-item">
                <div class="section-content__title">
                  <p class="section-content__title-txt">主要取引先</p>
                </div>
                <p class="section-content__body">- イチニ株式会社</p>
                <p class="section-content__body">- 株式会社Nitro Square</p>
                <p class="section-content__body">- NEXTGATE FOOD RELATIONS株式会社</p>
                <p class="section-content__body">- 株式会社リーデックス</p>
              </li>
              <li class="section-content__list-item">
                <div class="section-content__title">
                  <p class="section-content__title-txt">お問い合わせ</p>
                </div>
                <p class="section-content__body">- メール : <a class="mail-link" href="mailto:info@mobalab.net">info@mobalab.net</a></p>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </div>
  )
}

const AboutPage = (props) => (
  <Layout
    title="会社概要 | 株式会社もばらぶ"
    description="株式会社もばらぶは、千葉県茂原市にあるソフトウェア開発・IT企業です。遠隔勤務を推進しているため、日本・海外にメンバーが分散しています。"
    pathName={props.location.pathname}
    >
    <Hero
      title="ABOUT"
      titleJa="会社概要"
      body="もばらぶは遠隔勤務など新しい働き方を推進している、千葉県茂原市のソフトウェア開発・IT企業です。"
    />
    <Header />
    <AboutPageContent />
  </Layout>
)

export default AboutPage