import React from 'react'
import Particles from 'react-tsparticles'
import config from '../assets/lib/particles.json'

const Hero = (props) => {
  const {
    title,
    titleJa,
    body,
  } = props

  return (
    <div className="hero">
      <Particles className="hero__background" params={config}/>
      <div className="hero__title-area">
        <h1 className="hero__title">{ title }</h1>
        <p className="hero__title-ja">{ titleJa }</p>
        <p className="hero__body">{ body }</p>
      </div>
    </div>
  )
}

export default Hero
